.CreatePage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.LoginForm input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: thin #999 solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #f7ffd8;
}
.EmailInput {
}
.PasswordInput {
}

.CreatePage button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  width: 49%;
  border: thin #999 solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: inherit;
}
.LoginLink {
}
