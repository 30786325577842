.Container {
  position: relative;
  display: inline-block;
}
.Shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.Shadow div {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/blob.png);
  background-size: 100%;
  background-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}
