.PixelOverlay {
  pointer-events: none;
}

.PixelOverlay svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PixelOverlay rect {
  transition: opacity 0.3s ease-in-out;
}
