.ActionBar {
  /* grid-column: 1/4; */
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-items: center;
  text-align: center;
  /* align-content: center; */
  /* align-items: flex-start; */
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  /* max-width: 480px; */
}
.Left,
.Right,
.Main {
  position: relative;
  display: flex;
  flex-direction: column;
}
.Left {
  /* align-items: flex-start; */
}
.Right {
  /* align-items: flex-end;
  align-self: end;
  align-items: center; */
}
.Main {
  align-items: center;
  margin: 0;
  grid-column: 2/3;
  grid-row: 1/2;
  height: fit-content;
  width: 100%;
}
.Main svg {
  fill: #f7ffd8;
  height: 60px;
  margin-top: 0px;
}
.Main span {
  display: block;
  margin-top: 0px;
}
.Main span a {
  position: relative;
  display: block;
  margin-top: 0;
  height: 65px;
  fill: #dcee3f;
}

.Left {
  margin: auto;
  margin-top: 20px;
  grid-column: 1/2;
  grid-row: 1/2;
  width: 96px;
  display: block;
}
.Left a {
  fill: #f7ffd8;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.Left a svg {
  width: 40px;
}
.Left svg path {
  fill: #f7ffd8;
}

.Right {
  margin: auto;
  margin-top: 20px;
  grid-column: 3/4;
  grid-row: 1/2;
  width: 96px;
  display: block;
}
.Right a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.Right a svg {
  fill: #f7ffd8;
  width: 40px;
}

.ActionBar .Main svg {
  /* _opacity: 0.8; */
}
.Main a {
  margin-top: 0 !important;
}
.ActionBar label {
  font-size: 1rem;
  _background-color: rgba(222, 222, 162, 0.33);
  color: white;
  font-family: inherit;
  bottom: 0;
  margin-top: 15px;
  letter-spacing: 1px;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 700;
  box-sizing: border-box;
  line-height: 1;
  padding: 0;
  padding: 2px 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ActionBar .Left label {
  align-self: flex-end;
  display: none;
}
.ActionBar .Main label {
  align-self: stretch;
  display: block;
  margin-bottom: 20px;
  margin-top: 0px;
}
.ActionBar .Right label {
  align-self: flex-start;
  display: none;
}
