.Header {
  display: grid;
  grid-template-columns: 20px 48px auto 20px;
  padding-top: calc(5px + var(--header-x-pad, 0));
  box-sizing: border-box;
}
.LogoLink {
  position: absolute;
  display: block;
  width: 64px;
  height: 64px;
  top: 10px;
  right: 10px;
  justify-self: flex-end;
  grid-row: 1/2;
  grid-column: 3/5;
  margin: 0;
  margin-top: 0px;
  text-align: right;
  justify-self: right;
  color: #f7ffd8;
}
.MenuToggle {
  grid-row: 1/2;
  grid-column: 2/3;
}
.Logo {
  position: relative;
  display: block;
  width: 100%;
}
.LogoLink {
}
.LogoLink a {
  margin-top: 0;
}
