a.link_gravatar{
	display:block;
	width:100%;
	text-align: center;

}
.AvatarImage {
	display:block;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #f7ffd8;
  box-sizing: border-box;
  background-color:#f7ffd8;
}
