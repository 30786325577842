.AboutPage {
  position: relative;
  display: flex;
  _width: 100%;
  height: calc(100% - 40px);

  padding: 20px;
  background-color: #dfe0a2;
  background-color: rgba(250, 250, 250, 0.5);
  flex-wrap: wrap;
  flex-flow: column;
  box-sizing: border-box;
  align-content: center;
  align-items: center;

  _box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.AboutPageHeader {
  color: #333;
  margin-bottom: 20px !important;
  align-self: center;
  margin-top: auto;
}
.AboutPageSubHeader {
  color: #333;
}
p {
  margin-bottom: 20px;
  align-self: center;
  color: #333;
}
p:last-child {
  margin-bottom: auto;
}
