.CommentsButton {
  display: grid;
  justify-content: left;
  
}

.CommentsButton label {
  display: none;
}
.CommentsButton svg {
  position: relative;
  display: block;
  width: 32px;

  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: right;
  align-self: center;
  opacity: 1;
}
.CommentsButton span {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 10;
  color: #748000;
  font-weight: 600;
  font-size: 0.8rem;
  _padding-right: 20%;
  font-weight: 900;
  _width:40px;
  /* margin-top: 15px; */
}
.CommentsButton svg path {
  fill: #f7ffd8;
}
