.MyProfilePage {
}

.MyProfilePage section {
  /* JM moved to ../App/ContentSection.module.css */
}
.gravatar_caption {
  position: relative;
  display: none;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.gravatar_caption a {
  color: #333;
}

.profile_edit_field {
  position: relative;
  display: block;
}

.profile_edit_field label {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.profile_edit_field input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.MyProfilePage button {
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: inherit;
  background-color: rgba(223, 223, 162, 1);
  color: #333;
  width: 33%;
}
