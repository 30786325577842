.LogoutPage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.LogoutPage h2{
	display: none;
}
.LogoutPage button {
  display: inline-block;
    padding: 10px 10px;
    margin-bottom: 00px;
    box-sizing: border-box;
    font-size: 0.9rem;
    width: 33%;
    margin-right: 2%;
    border: none;
    -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
    font-family: inherit;
    background-color: rgba(223,223,162,1);
    color: #555;
    text-transform: capitalize;
    font-weight: 600;
	height: fit-content;
	align-self: center;
}
