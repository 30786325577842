.Menu {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  background-color: rgba(55, 55, 55, 1);
}
.Menu.Show {
  transform: translateX(0);
}

.Menu ul {
  position: relative;
  display: grid;
  grid-template-columns: 20px auto 20px;
  list-style: none;
  height: auto;
  margin: 0;
  padding: 0;
  padding-top: 10%;
  padding-bottom: 15%;
  box-sizing: border-box;
}

.Menu ul li {
  position: relative;
  display: flex;
  grid-column: 2/3;
  grid-row: span 1;
  align-content: center;
}
.Menu ul li.AccountMenuItem {
  display: grid;
  grid-template-columns: auto 1fr;
}

li.AccountMenuItem a img {
  margin-right: 20px;
}
.Menu ul li a {
  position: relative;
  display: block;
  text-decoration: none;
  align-self: center;
  color: #eee;
  font-size: 1.5rem;
  text-transform: lowercase;
  font-variant: all-small-caps;
  letter-spacing: 2px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.AccountMenuItem a {
  height: 30px;
  position: relative;
  display: block;
}
.AccountMenuItem a img {
  /* display: inline-block; */
}
