.MenuToggle {
  position: relative;
  display: block;
  width: 27px;
  height: auto;
  align-self: center;
  padding: 0 !important;
  transform:scale(1.5)!important;
  margin-left:10px!important;
  margin-TOP:10px!important;
}
