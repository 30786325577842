.CommentsListing {
  position: relative;
  display: block;
  width: 100%;

  box-sizing: border-box;
  max-width: 480px;
}

.Comment {
  position: relative;
  display: block;
  /* width: 100%; */
  margin-left: calc(0px - var(--app-horizontal-padding));
  margin-right: calc(0px - var(--app-horizontal-padding));
  padding: 15px calc(5px + var(--app-horizontal-padding));
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  margin-bottom: 5px;

  overflow: hidden;
}

.Comment p {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 5px;
  color: #333;
  font-weight: 400;
}

.MetaData {
  display: grid;
  grid-template-columns: 0px 1fr auto;
  align-content: center;
  justify-items: left;
}
.MetaData time {
  font-size: 0.8rem;
  grid-row: 1/2;
  grid-column: 3/4;
  align-self: center;
  font-family: inherit;
  margin-top: 12px;
  font-style: italic;
  font-weight: 600;
  opacity: 0.5;
}
.MetaData a {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  text-decoration: none;
  color: #333;
  font-weight: 900;
  font-size: 0.8rem;
  margin-top: 10px;
}
.MetaData a:first-of-type {
  grid-row: 1/2;
  grid-column: 2/3;
  padding-left: 0px;
  align-self: center;
}
.MetaData img {
  max-height: 30px;
  display: none;
}
