h2.caimTitle {
  display: block;
  align-self: flex-end;
  text-align: center;
  color: #f7ffd8;
  bottom: 0;
  margin: auto;
  text-transform: lowercase;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  margin-top: 340px;
  font-size: 2rem;
  z-index: 2;
}
h2.caimTitle::before {
  _content: " ";
  position: fixed;
  top: 40px;
  left: 20px;
  height: 100%;
  width: 100%;
  background-image: url(../../assets/scanlines.png);
  z-index: 1;
  opacity: 0.4;
}
h2.caimTitle::after {
  content: " ";
  position: fixed;
  top: 50px;
  left: 20px;
  height: 400px;
  width: 100%;
  _z-index: -1;
  background-image: url(../../assets/parrot.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.Parrot {
  position: relative;
  max-height: 400px;
  max-width: 100%;
  top: -60px;
  margin-bottom: -100px;
  left: calc(50% + 20px);
  transform: translateX(-50%);
}
.HomePage p {
  position: relative;
  display: block;
  font-size: 1.4rem;
  font-weight: 900;
  hyphens: auto;
  color: #f7ffd8;
  margin-bottom: 30px;
  text-align: justify;
}

.HomePage p small {
  position: relative;
  display: block;
  font-size: 1.8rem;
  font-weight: 900;
  hyphens: auto;
  color: #f7ffd8;
  margin-top: 10px;
}
.HomePage p span {
  display: block;
  text-transform: lowercase;
  font-variant: all-small-caps;
  font-size: 2.4rem;
  text-align: center;
  line-height: 1;
  margin-top: 20px;
}

.BrowseButton {
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  /* background-color: #f7ffd8; */
  color: #f7ffd8;
  font-size: 1.2rem;
  border-radius: 3px;
  z-index: +1;
  top: 0px;
  font-weight: 900;
  /* border: 4px #f7ffd8 solid; */
  text-align: right;
  align-self: center;
  justify-self: flex-end;
  margin-left: auto;
}

.BrowseButton_blur {
  position: relative;
  display: block;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  background-color: #f7ffd8;
  color: #333;
  font-size: 0.9rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  filter: blur(5px);
  opacity: 0.8;
  bottom: 80px;
  left: 50px;
}

.LoginButton {
  position: relative;
  display: none;
  font-size: 0.9rem;
  padding: 10px 5px;
  padding-right: 30px;
  margin-top: 50px;
}

.SignupButton {
  position: relative;
  display: none;
  font-size: 0.9rem;
  padding-right: 30px;
  margin-top: 50px;
}
.colorDummy {
  position: fixed;
  display: none;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  z-index: -1;
  color: transparent;
}
