.SharePanel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-content: center;
  width: 100vw;
  max-width: 480px;
  padding: 25px;
  box-sizing: border-box;
  background: #f7ffd8;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: rgba(127,135,37,0.8);
  z-index: 10000;
}
.SharePanel span {
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: right;
}

.SharePanel a {
  display: inline-block;
  grid-column: span 1;
  grid-row: 2/3;
  text-align: center;
}

.SharePanel a:first-of-type {
  grid-column: 1/4;
  grid-row: 3/4;
}
.SharePanel a img {
  max-height: 5rem;
  height: 40px;
  width: 40px;
}
.Feedback {
}
.CloseButton svg {
  width: 30px;
}
.CloseButton svg path {
  fill: black;
}
