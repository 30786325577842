.Container {
  position: relative;
  display: block;
  width:100%;
}
.Original {
  position: relative;
  display: block;
  width: 100%;
    display: inline;
  background: rgba(121,127,43,0.5);
  box-shadow: 15px 0 0 rgba(121,127,43,0.5), -20px 0 0 rgba(121,127,43,0.5);
}

.Blur {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  z-index: -1;
}
